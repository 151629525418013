import React from 'react'
import { UserMenuDropdown } from '../UserMenuDropdown'
import { Notifications } from '../Notifications'
import * as Styled from './UserButtonsList.styles'

const UserButtonsList: React.FC = () => (
  <Styled.List>
    <Styled.Item>
      {/* <Notifications /> */}
      <UserMenuDropdown />
    </Styled.Item>
  </Styled.List>
)

export default UserButtonsList
