import React, { memo } from 'react'
import SVG from 'react-inlinesvg'
import { Link } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Icon, IconName, ThemeName, themes } from '@unbounded/unbounded-components'
import Paths from '~routes/paths'
import unboundedLogo from '~assets/unbounded-logo.svg'
import * as Styled from './Footer.styles'
import JoinCommunity from './JoinCommunity'

const Footer: React.FC = memo(() => (
  // Footer must use a `dark` theme by design
  <ThemeProvider theme={themes[ThemeName.unboundedDark]}>
    <Styled.Container>
      <Styled.Content>
        <Styled.Summary>
          <Styled.UnboundedContainer>
            <Styled.Logo href="https://unbounded.network/" target="_blank" rel="noopener noreferrer">
              <SVG src={unboundedLogo} width={170} height={34} title="Unbounded Network" />
            </Styled.Logo>

            <JoinCommunity />
          </Styled.UnboundedContainer>

          <Styled.LinksContainer>
            {/* <Styled.LinksSection> */}
            {/*  <Styled.LinksHeader>Products</Styled.LinksHeader> */}

            {/*  <Styled.LinksGroup> */}
            {/*    <Styled.Link href="https://mipasa.com/" target="_blank" rel="noopener noreferrer"> */}
            {/*      <Styled.LinkIcon name={IconName.brand_colored_mipasa} /> MiPasa */}
            {/*    </Styled.Link> */}
            {/*    <Styled.Link href="https://papillon.unbounded.network/" target="_blank" rel="noopener noreferrer"> */}
            {/*      <Styled.LinkIcon name={IconName.brand_colored_papillon} /> Papillon */}
            {/*    </Styled.Link> */}
            {/*    <Styled.Link href="https://unbounded.network/wallet" target="_blank" rel="noopener noreferrer"> */}
            {/*      <Styled.LinkIcon name={IconName.brand_colored_unwallet} /> Unbounded Wallet */}
            {/*    </Styled.Link> */}
            {/*  </Styled.LinksGroup> */}
            {/* </Styled.LinksSection> */}

            {/* <Styled.LinksSection> */}
            {/*  <Styled.LinksHeader>Company</Styled.LinksHeader> */}

            {/*  <Styled.LinksGroup> */}
            {/*    <Styled.Link as={Link} to={Paths.about}> */}
            {/*      About us */}
            {/*    </Styled.Link> */}
            {/*    <Styled.Link as={Link} to={Paths.services}> */}
            {/*      Services */}
            {/*    </Styled.Link> */}
            {/*    <Styled.Link as={Link} to={Paths.solutions}> */}
            {/*      Solutions */}
            {/*    </Styled.Link> */}
            {/*    <Styled.Link as={Link} to={Paths.partners}> */}
            {/*      Partners */}
            {/*    </Styled.Link> */}
            {/*  </Styled.LinksGroup> */}
            {/* </Styled.LinksSection> */}

            <Styled.LinksSection>
              <Styled.LinksHeader>Info</Styled.LinksHeader>

              <Styled.LinksGroup>
                <Styled.Link as={Link} to={Paths.termsOfUse}>
                  Terms of Use
                </Styled.Link>
                <Styled.Link as={Link} to={Paths.privacyPolicy}>
                  Privacy Policy
                </Styled.Link>
                <Styled.Link as={Link} to={Paths.FAQ}>
                  FAQ
                </Styled.Link>
                <Styled.Link as={Link} to={Paths.contact}>
                  Contact Us
                </Styled.Link>
              </Styled.LinksGroup>
            </Styled.LinksSection>
          </Styled.LinksContainer>
        </Styled.Summary>

        <Styled.Copyright>
          <Styled.CopyrightText>
            Copyright&nbsp;©&nbsp;{new Date().getFullYear()}{' '}
            <Styled.CopyrightLink href="https://unbounded.network">https://unbounded.network</Styled.CopyrightLink>
          </Styled.CopyrightText>

          <Styled.Social>
            <Styled.SocialLink href="https://discord.gg/sZVjbxpfft" target="_blank" rel="noopener noreferrer" title="Discord">
              <Icon name={IconName.brand_squared_discord} size="large" />
            </Styled.SocialLink>

            <Styled.SocialLink href="https://twitter.com/UnboundedNet" target="_blank" rel="noopener noreferrer" title="Twitter">
              <Icon name={IconName.brand_squared_twitter} size="large" />
            </Styled.SocialLink>

            <Styled.SocialLink href="https://www.linkedin.com/company/unboundednetwork/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
              <Icon name={IconName.brand_squared_linkedin} size="large" />
            </Styled.SocialLink>

            <Styled.SocialLink
              href="https://www.youtube.com/channel/UCyW03yGdYZbKR7P-MoFsuYg"
              target="_blank"
              rel="noopener noreferrer"
              title="YouTube"
            >
              <Icon name={IconName.brand_squared_youtube} size="large" />
            </Styled.SocialLink>
          </Styled.Social>
        </Styled.Copyright>
      </Styled.Content>
    </Styled.Container>
  </ThemeProvider>
))

Footer.displayName = 'Footer'
export default Footer
