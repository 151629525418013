import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import { LoadingArea, Modal, spacing, ModalProps } from '@unbounded/unbounded-components'
import { isEmbedMessage } from './IDM.utils'
import * as Styled from './IDM.styles'

interface Props extends ModalProps {
  startAt: 'login' | 'logout' | 'register'
  onSuccess?: () => void
}

export function getIDMUrl() {
  if (window.location.host.indexOf('test.') !== -1 || window.location.host.indexOf('staging.') !== -1) {
    return 'https://id.staging.mipasa.com'
  }
  if (window.location.host.indexOf('localhost') !== -1) {
    return 'http://localhost:3400'
  }
  return 'https://id.mipasa.com'
}

const IDM: React.FC<Props> = ({ startAt, onSuccess, ...props }: Props) => {
  const initialFrameUrl = getIDMUrl()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const ref = useRef<HTMLIFrameElement>(null)
  const theme = useTheme()

  const handleIDMEvents = (e: MessageEvent) => {
    if (!isEmbedMessage(e.data)) {
      return
    }

    const msg = e.data

    if (msg.type === 'form-size' && ref.current) {
      // the spacing here refers to padding around iframe in the modal.
      ref.current.style.minWidth = `min(${msg.width}px, calc(100vw - ${spacing(3)({ theme })}))`
      ref.current.style.minHeight = `${msg.height}px`
    } else if (msg.type === 'login-success') {
      // handle login success
      if (onSuccess) {
        onSuccess()
      }
    } else if (msg.type === 'logout-success') {
      // handle logout success
      if (onSuccess) {
        onSuccess()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleIDMEvents)

    if (ref.current) {
      ref.current.addEventListener('load', () => setIsLoading(false))
    }
    return () => window.removeEventListener('message', handleIDMEvents)
  })

  const redirectAfterOAuth = window.location.href.substring(window.location.origin.length)
  const idmUrl = `${initialFrameUrl}/${startAt}?theme=unbounded-light&to=${encodeURIComponent(
    `${window.location.origin}/logged-in`
  )}&state=${encodeURIComponent(redirectAfterOAuth)}`

  if (startAt === 'login' || startAt === 'register' || startAt === 'logout') {
    window.location.href = idmUrl
    return null
  }

  return (
    <Modal {...props}>
      <Styled.IDMWrapper>
        <LoadingArea isLoading={isLoading}>
          <Styled.IDMFrame ref={ref} title="MiPasa ID" src={idmUrl} isLoading={isLoading} />
        </LoadingArea>
      </Styled.IDMWrapper>
    </Modal>
  )
}

export default IDM
