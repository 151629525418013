import {
  BaseColor,
  Breakpoint,
  breakpointStarting,
  Color,
  linkReset,
  rem,
  roundedCorners,
  spacing,
  themeColor,
  transitions,
  Typography,
  Button,
} from '@unbounded/unbounded-components'
import styled from 'styled-components'
import { SectionLargeHeadline } from '../LandingPage.styles'

const breakpoint = breakpointStarting(Breakpoint.lg)

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`

export const Title = styled(SectionLargeHeadline)`
  max-width: ${rem(300)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    max-width: ${rem(550)};
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1.5)};
  padding: ${spacing(4)} 0 ${spacing(2)};

  @media (${breakpointStarting(Breakpoint.md)}) {
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(4)};
    padding: 0;
  }

  @media (${breakpointStarting(Breakpoint.lg)}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Link = styled.a`
  display: flex;
  ${linkReset};
`

export const Item = styled.article`
  flex-grow: 1;
  display: flex;
  gap: ${spacing(2)};
  flex-direction: column;
  padding: ${spacing(2)};
  background-color: ${themeColor(BaseColor.shade_10)};
  outline: 1px solid transparent;
  ${transitions(['outline-color'])};
  ${roundedCorners};

  &:hover {
    outline: 1px solid ${themeColor(BaseColor.shade_30)};
  }
`

export const ItemTitle = styled.h4`
  ${Typography.headlineMedium};
  color: ${themeColor(Color.primary)};

  @media (${breakpoint}) {
    ${Typography.headlineLarge};
  }
`
export const ItemDescription = styled.p`
  ${Typography.bodySmall};

  @media (${breakpoint}) {
    text-align: left;
    max-width: none;
    ${Typography.bodyMedium};
  }
`
export const ItemCopyright = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: ${spacing(1)};
  color: ${themeColor(BaseColor.shade_40)};
`

export const CopyrightLogo = styled.img`
  width: ${rem(30)};
  height: ${rem(22)};
`

export const ViewAllContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing(2)};
`

export const ViewAllButton = styled(Button)``
