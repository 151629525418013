import React from 'react'
import * as Styled from './ProductsSection.styles'

const ProductsSection: React.FC = () => (
  <Styled.Container>
    <Styled.Title>An Ecosystem of Unbounded Apps™</Styled.Title>
  </Styled.Container>
)

export default ProductsSection
