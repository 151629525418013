import styled from 'styled-components/macro'
import {
  BaseColor,
  Breakpoint,
  breakpointStarting,
  Color,
  ColorShade,
  FlexibleContainer,
  Icon,
  link,
  linkReset,
  rem,
  spacing,
  themeColor,
  transitions,
  Typography,
} from '@unbounded/unbounded-components'

const startingDesktop = breakpointStarting(Breakpoint.md)

export const Container = styled.footer`
  flex: 0 0 auto;
  background: ${themeColor(BaseColor.shade_10)};
  color: ${themeColor(BaseColor.shade_70)};
`

export const Content = styled(FlexibleContainer)`
  padding: 0 ${spacing(1)};

  @media (${startingDesktop}) {
    width: 100%;
  }
`

export const Summary = styled.div`
  padding: ${spacing(2)} 0 ${spacing(1)};

  @media (${startingDesktop}) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: ${spacing(1.5)};
  }
`

export const UnboundedContainer = styled.div`
  margin-bottom: ${spacing(2)};

  @media (${startingDesktop}) {
    margin-bottom: 0;
    margin-right: ${spacing(2)};
    width: 50%;
  }
`

export const Logo = styled.a`
  ${linkReset};
  display: inline-flex;
  margin-bottom: ${spacing()};

  svg {
    color: ${themeColor(BaseColor.shade_100)};
    fill: ${themeColor(Color.primary)};
  }
`

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1.5)};

  @media (${startingDesktop}) {
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
  }
`

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (${startingDesktop}) {
    &:only-child {
      margin-left: auto;
    }
  }
`

export const LinksHeader = styled.h4`
  ${Typography.titleSmall};
  ${Typography.textSemiStrong}
  margin-bottom: ${rem(10)};
  color: ${themeColor(Color.primary)};
`

export const LinksGroup = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Link = styled.a`
  ${linkReset};
  ${Typography.bodySmall};
  padding: ${spacing(0.25)} 0;
  display: flex;
  align-items: center;
  color: ${themeColor(BaseColor.shade_70)};
  ${transitions(['color'])};

  &:hover {
    color: ${themeColor(BaseColor.shade_90)};
  }
`

export const LinkIcon = styled(Icon)`
  margin-right: ${spacing(0.5)};
`

export const Copyright = styled.div`
  ${Typography.bodySmall};
  position: relative;
  margin-top: ${rem(52)};
  padding: ${rem(14)} 0;
  border-top: 1px solid ${themeColor(Color.primary, ColorShade.soft)};
  color: ${themeColor(Color.primary, ColorShade.semiSoft)};

  @media (${startingDesktop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
  }
`

export const CopyrightText = styled.div`
  ${Typography.bodySmall};
`

export const CopyrightLink = styled.a`
  ${link};
  color: inherit;

  &:hover {
    color: ${themeColor(Color.primary)};
  }
`

export const Social = styled.div`
  display: inline-flex;
  color: ${themeColor(Color.primary)};
  margin-bottom: ${rem(20)};
  position: absolute;
  bottom: 100%;

  @media (${startingDesktop}) {
    position: static;
    margin-bottom: 0;
  }
`

export const SocialLink = styled.a`
  ${linkReset};
  display: flex;
  justify-content: center;
  align-items: center;

  & + & {
    margin-left: ${spacing(1)};
  }

  color: ${themeColor(Color.primary, ColorShade.soft)};
  ${transitions(['color'])};

  &:hover {
    color: ${themeColor(Color.primary)};
  }
`
